@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a.active {
  font-weight: 600;
  background-color:rgb(241 245 249 / var(--tw-bg-opacity));
}

#bottom-nav a.active {
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
  color: #00940C;
}

.react-daterange-picker__wrapper {
  border: 0 !important;
  justify-content: center !important;
  flex-grow: 0 !important;
}

.react-daterange-picker {
  justify-content: center;
}